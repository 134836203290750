@keyframes infiniteMove {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    transform: translateX(1000px);
    opacity: 0;
  }

  99% {
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

.moving-container {
  animation-duration: 3000ms;
  animation-name: infiniteMove;
  animation-timing-function: ease-in;
  animation-delay: 0s;
  animation-iteration-count: infinite;
}

@keyframes scrollHorizontally {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes scrollSkewedHorizontally {
  0% {
    transform: translateX(-40px);
  }
  100% {
    transform: translateX(calc(-50% - 40px));
  }
}

.infinite-scroll-animation {
  animation: scrollHorizontally 300s linear infinite;
}

.infinite-scroll-skewed-animation {
  animation: scrollSkewedHorizontally 300s linear infinite;
}

.infinite-scroll-content {
  display: flex;
}

.infinite-scroll-content > div {
  flex: 0 0 auto;
  gap: 30px;
}
