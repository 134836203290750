@font-face {
  font-family: "playfair";
  src: url("./fonts/PlayfairDisplay-Regular.ttf") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "playfair-semi";
  src: url("./fonts/PlayfairDisplay-SemiBold.ttf") format("woff2");
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3 {
  font-family: "playfair-semi", sans-serif;
  color: #313131;
}

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-heading-primary {
  color: #313131;
  font-family: "playfair-semi", sans-serif;
}
.text-color-primary {
  color: #313131;
}

.text-heading-secondary {
  color: #313131;
  font-weight: 600;
  font-family: sans-serif;
}

.text-body-bold {
  color: #616161;
  font-weight: 600;
  font-family: sans-serif;
}

.text-body-light {
  color: #616161;
  font-family: sans-serif;
}

.description-text {
  line-height: 1.25;
}

.overflow-auto {
  overflow-x: auto; /* Enables horizontal scrolling */
  overflow-y: hidden; /* Prevents vertical scrolling */
  white-space: nowrap; /* Ensures the contents are in a single line */
}

/* To make the NavBar fixed & responsive in react-bootstrap*/
@media screen and (width <= 768px) {
  .mainContainer {
    margin-top: 140px;
  }
  .flash-message {
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .mainContainer {
    margin-top: 100px;
  }
  .flash-message {
    margin-top: 55px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .mainContainer {
    margin-top: 100px;
  }
  .flash-message {
    margin-top: 55px;
  }
}
@media screen and (min-width: 1200px) {
  .mainContainer {
    margin-top: 100px;
  }
  .flash-message {
    margin-top: 120px;
  }
  .banner-image {
    max-height: 30vh;
  }
}

.banner-image {
  height: 10vh;
  border-radius: 12px;
}

.flash-message {
  position: absolute;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* To add the no. of items to the cart icon in Navbar*/
.cart-basketMobile {
  font-size: 0.7rem;
  position: relative;
  right: 9px;
  top: -27px;
  padding: 0px;
  margin: 0px;
}

.cart-basketSmall {
  font-size: 0.9rem;
  color: #ffffff;
  height: 1.1rem;
  width: 1.1rem;
  background-color: #418deb;
  border-radius: 50%;
  position: relative;
  right: -15px;
  top: -10px;
  padding: 0px;
  margin: 0px;
}
.cart-basketBig {
  font-size: 0.8rem;
  color: #ffffff;
  height: 1rem;
  width: 1rem;
  background-color: #418deb;
  border-radius: 50%;
  position: relative;
  right: -15px;
  top: -2px;
  padding: 0px;
  margin: 0px;
}

.fa-shopping-cart {
  position: relative;
  top: -10px;
}
main {
  min-height: 60vh;
}
/* Making rounded Cart buttons*/
.btn-cart {
  height: 20px;
}

/*The OTP inputs */
.otpform {
  display: inline-flex;
}
.otp {
  max-width: 2rem;
  margin-right: 5px;
}

/*Fixing max-height of the Navbar*/
/*
.navbarBig {
  padding:0;
  justify-content: space-between;
}

.navContainerBig {
  margin-top: 0;
  margin-left:15px;
  margin-right:15px;
  padding:0;
}*/

/*Fixing max-height of the offcanvas in homescreen*/
.cartShowOffcanvas {
  max-height: 18vh;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.cartShowBody {
  margin-top: 0px;
  padding-top: 0px;
}
.cartShowTitle {
  padding-top: 5px;
  padding-bottom: 10px;
  margin-top: 0px;
}

/*Plus minus button styling*/
.plusMinus {
  margin-right: 5px;
}

.wide-btn {
  min-width: 35px;
}

/*Login form container styling*/
.Login {
  margin-top: 1.1rem;
  border-style: solid;
  border-color: #d9230f;
  border-radius: 10px;
  border-width: 1px;
  padding: 1.5rem;
}
.LoginTitle {
  color: #d9230f;
  margin-bottom: 1.5rem;
}

.product-deltime-tag {
  position: absolute;
  top: 15px;
  left: -7px;
  width: 40%;
}

.product-rating-badge {
  position: absolute;
  bottom: 16px;
  right: 8px;
}
/* Listing screen */
.product-listing-image {
  position: relative;
  text-align: center;
  color: white;
  overflow: hidden;
}

.product-rating-badge {
  position: absolute;
  bottom: 16px;
  right: 8px;
}

.product-speciality-tag {
  position: absolute;
  bottom: 16px;
  left: 8px;
}

.preorder-tag {
  background-color: #006e45 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  border-radius: 0px;
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
  text-align: left;
}

.seller-name-listing {
  font-size: 0.9rem;
}

.banner {
  background-color: #f1813f;
  width: 100vw;
}
.Categories {
  margin-top: 5px;
  padding: 0;
}
.Category:active {
  background-color: #ead5c9;
}
.Category:hover {
  background-color: #ead5c9;
}
.Category {
  cursor: pointer;
}
.Trash {
  align-self: center;
}
.AddressCard {
  cursor: pointer;
}
.FillAddCard {
  background-color: #ead5c9;
}
/* For the Tabs in react-bootstrap used in CreateBatchTable screen*/
.nav-tabs .nav-link.active {
  background-color: blue !important;
  color: white !important;
}
.BlueTitle {
  background-color: #418deb;
  color: #ffffff;
  padding-left: 1%;
}
.RedTitle {
  background-color: red;
  color: #ffffff;
  padding-left: 1%;
}

.PurpleTitle {
  background-color: #9107bb;
  color: #ffffff;
  padding-left: 1%;
}
.edit-capacity {
  display: inline;
  width: 4.5em;
  margin-left: 5px;
  margin-right: 5px;
}

.search-form {
  position: relative;
  width: "100%";
}

.search-input {
  border-radius: 8px; /* Adjust as needed for roundness */
  padding-right: 50px; /* Adjust based on the size of your button */
  background-color: #ededed;
  border-color: transparent;
}

.search-button {
  position: absolute;
  right: 0;
  top: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 100%; /* Match the height of the input */
  background: transparent;
  border-color: transparent;
}

.search-button:hover,
.search-button:active,
.search-button:focus {
  border-color: transparent;
  background: rgba(0, 110, 69, 0.2);
}

.banner {
  border-radius: 8px; /* Adjust as needed for roundness */
}

.theme-red-tile {
  background-color: #d9230f;
  color: #ffffff;
  padding-left: 1%;
}

.brand-title {
  background-color: #d9230f;
  color: #ffffff;
  padding-left: 1%;
}

.carousel .carousel-indicators button {
  background-color: white;
  border: none;
  border-radius: 50%;
  width: 5px;
  height: 5px;
}

.modal-fullscreen {
  width: 90vw;
  height: 90vh;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.iframe-fullscreen {
  width: 100%;
  height: 100%;
}

.image-page {
  width: 100%; /* Adjust the width as necessary */
  max-width: 600px; /* Approximate width for A4 paper in pixels at 96 DPI */
  height: auto; /* For the image, this ensures the aspect ratio is maintained */
  aspect-ratio: 1 / 1.41; /* For the iframe, ensures it maintains an A4 aspect ratio */
  display: block;
  margin: 20px auto; /* Center the element with some margin */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds shadow for depth */
  border: 1px solid #ddd; /* Adds a light border around the element */
  background-color: #fff; /* Background color for the container */
}

.add-button {
  float: right;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 600;
}

.add-button-colors {
  background: rgba(0, 110, 69, 0.05);
  border: 1px solid rgba(0, 110, 69, 1);
  color: rgba(0, 110, 69, 1);
}

.add-button-colors:hover {
  background: rgba(0, 110, 69, 1);
  border: 1px solid rgba(0, 110, 69, 1);
  color: white;
}

.bold-button {
  font-weight: 600;
}

.p-style {
  margin: 0;
  font-size: large;
  padding: 0;
  line-height: 1.1;
}

.address-modal {
  width: 90vw;
  height: 90vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  /* background: blue ;
  background-color: aqua; */
}

.button-container-sticky {
  position: fixed;
  padding: 15px 10px;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.round-gray-container {
  margin-top: 15;
  background: #f5f5f5;
  padding: 20;
  border-radius: 10;
  width: "100%";
}

.stretch-component-margin {
  margin-left: -20px;
  margin-right: -20px;
  padding: 0;
}

.stretch-component-full {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.form-control-bold {
  border-radius: 10px;
  margin-bottom: 15px;
  color: #313131;
  font-weight: 600;
}

.otp-input {
  font-size: 20px;
  letter-spacing: 8px;
  width: 100%;
  max-width: 200px;
  font-weight: 600;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.scrollable-column {
  height: 80vh;
  overflow-y: scroll;
}

.selected-item {
  transform: scale(1.05);
  transition: transform 0.2s ease-in-out;
}

.subcategory-tile {
  transition: transform 0.2s ease-in-out;
}
.selected-background {
  background-color: #ead5c9;
}

.rounded-4 {
  border-radius: 0.4rem !important;
}

.mb-6 {
  margin-bottom: 5rem;
}

.placeholder-glow .placeholder {
  -webkit-animation: custom-placeholder-glow 2s ease-in-out infinite !important;
  animation: custom-placeholder-glow 2s ease-in-out infinite !important;
}
@-webkit-keyframes custom-placeholder-glow {
  0%,
  100% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.05;
  }
}
@keyframes custom-placeholder-glow {
  0%,
  100% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.05;
  }
}
