.modal-fullscreen {
  width: 80vw !important;
  height: 95vh !important;
  margin: auto !important;
  display: flex !important;
  flex-direction: column !important;
}

.form-floating > .form-control {
  border-radius: 10px; /* Your custom border-radius */
  margin-bottom: 15px;
  color: #313131;
  font-weight: 600;

  /* Additional styles for Form.Control */
}

.address-style {
  background: #d3d3d3;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.map-button {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  background: white;
  color: #d9230f;
  border-radius: 10px;
  border: 0px;
  width: fit-content;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-weight: 600;
}

.two-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4em; /* Adjust based on your design */
  max-height: 2.8em; /* 2 times the line-height for 2 lines */
  white-space: normal;
}

.sticky-top {
  position: sticky;
  z-index: 10;
  background: black;
  color: white;
  border-radius: 10px;
  border: 0px;
}
